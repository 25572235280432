import React from 'react';
import Button from 'ui/elements/Button';

import { useGetInternalLink } from 'utils/helpers'

import Link from './Link';

const CTA = ({
  node,
}) => {
  const opts = {
    variant: 'outlined',
    color: 'shade',
    iconName: 'arrow-right',
    label: node.text,
  };

  const internal = useGetInternalLink(node.internalLink)

  if (!node.internalLink && !node.externalLink) {
    return null;
  }

  if (node.externalLink) {
    return (
      <Button
        href={node.externalLink}
        target='_blank'
        {...opts}
      />
    )
  }

  return (
    <Button
      as={Link}
      to={internal}
      {...opts}
    />
  )
};

export default CTA;
